
<template>
   <div class="div-main-list-page invoice-main">
    <div class="div-main-list-page-title" v-if="view === INVOICE_VIEW.MAIN_INVOICE">
      <h1>Invoice</h1>
    </div>
    <el-row class="top-tab-botton-bar" :gutter="10" v-if="view === INVOICE_VIEW.MAIN_INVOICE">
      <el-col  :xs="24" :sm="8" :md="10" class="tab-bar" style="margin-bottom:8px">
        <el-tabs v-if="isSellerView" v-model="viewInvoiceTabActive" @tab-click="handleGetInvoices(invoicePagination.pageSize, 0)">
          <el-tab-pane label="Pending Credit Authorization" :name="INVOICE_TAB.RECEIVABLE_PENDING_CREDIT_AUTHORIZATION"/>
          <el-tab-pane label="Pending Authorized Signatory Approval" :name="INVOICE_TAB.RECEIVABLE_PENDING_AUTHORIZED_SIGNATORY_APPROVAL"/>
          <el-tab-pane label="Approved" :name="INVOICE_TAB.RECEIVABLE_AUTHORIZED_SIGNATORY_APPROVED"/>
          <el-tab-pane label="Rejected" :name="INVOICE_TAB.RECEIVABLE_REJECTED"/>
          <el-tab-pane label="Sent" :name="INVOICE_TAB.RECEIVABLE_SENT"/>
          <el-tab-pane label="Paid" :name="INVOICE_TAB.RECEIVABLE_PAID"/>
          <el-tab-pane label="Archive" :name="INVOICE_TAB.RECEIVABLE_CANCELLED"/>
        </el-tabs>
        <el-tabs  v-else v-model="viewInvoiceTabActive" @tab-click="handleGetInvoices(invoicePagination.pageSize, 0)">
          <el-tab-pane label="Pending Trader Approval" :name="INVOICE_TAB.PAYABLE_PENDING_TRADER_APPROVAL"/>
          <el-tab-pane label="Pending Authorized Signatory Approval" :name="INVOICE_TAB.PAYABLE_PENDING_AUTHORIZED_SIGNATORY_APPROVAL"/>
          <el-tab-pane label="Approved" :name="INVOICE_TAB.PAYABLE_AUTHORIZED_SIGNATORY_APPROVED"/>
          <el-tab-pane label="Paid" :name="INVOICE_TAB.PAYABLE_PAID"/>
          <el-tab-pane label="Rejected" :name="INVOICE_TAB.PAYABLE_REJECTED"/>
        </el-tabs>
      </el-col>
      <el-col  :xs="24" :sm="16" :md="14" class="flex-end" style=" white-space: nowrap">
          <el-input
            class="round-input search-bar"
            placeholder="Search Order No."
            v-model="invoiceSearch"
            @keypress.enter.native="handleGetInvoices(invoicePagination.pageSize, 0, true)"
          >
            <el-button type="primary" slot="append" icon="el-icon-search" @click="handleGetInvoices(invoicePagination.pageSize, 0, true)"></el-button>
          </el-input>
        <span style="padding-top:5px; white-space: nowrap"
          v-if="(viewInvoiceTabActive === INVOICE_TAB.PAYABLE_PENDING_TRADER_APPROVAL && hasPermission($permissionFunctionCode.TRADER_APPROVAL)) ||
            ((viewInvoiceTabActive === INVOICE_TAB.PAYABLE_PENDING_AUTHORIZED_SIGNATORY_APPROVAL || viewInvoiceTabActive === INVOICE_TAB.RECEIVABLE_PENDING_AUTHORIZED_SIGNATORY_APPROVAL) &&
              hasPermission($permissionFunctionCode.AUTHORIZED_SIGNATORY_APPROVAL)
            )"
        >
          <el-button
            round
            outline
            type="success"
            @click="authSignatoryTraderApproveReject(viewInvoiceTabActive === INVOICE_TAB.PAYABLE_PENDING_TRADER_APPROVAL, true)"
          >
            Approve
          </el-button>
          <el-button
            round

            type="danger"
            @click="authSignatoryTraderApproveReject(viewInvoiceTabActive === INVOICE_TAB.PAYABLE_PENDING_TRADER_APPROVAL, false)"
          >
            Reject
          </el-button>
        </span>
        <span style="padding-top:5px"
          v-if="hasPermission($permissionFunctionCode.ADD)">
          <el-dropdown class="primary-button" trigger="click" @command="handleCreateInvoiceSelect">
            <span class="el-dropdown-link">
              Create Invoice<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="EX_WHARF">Ex-Wharf</el-dropdown-item>
              <el-dropdown-item command="DELIVERED">Delivered</el-dropdown-item>
              <el-dropdown-item command="BARGING_NOMINATION">Barging Nomination</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
      </el-col>
    </el-row>
    <div class="div-main-list-page-table" v-if="view === INVOICE_VIEW.MAIN_INVOICE" v-loading="invoiceTableLoading">
      <el-table
        :data="invoiceTableData"
        height="100%"
        :border="true"
        :header-cell-style="HEADER_CELL_STYLE"
        :cell-style="CELL_STYLE"
        v-if="!invoiceTableLoading"
        @selection-change="handleInvoiceSelectChange"
      >
        <el-table-column
          v-if="(viewInvoiceTabActive === INVOICE_TAB.PAYABLE_PENDING_TRADER_APPROVAL && hasPermission($permissionFunctionCode.TRADER_APPROVAL)) ||
              ((viewInvoiceTabActive === INVOICE_TAB.PAYABLE_PENDING_AUTHORIZED_SIGNATORY_APPROVAL || viewInvoiceTabActive === INVOICE_TAB.RECEIVABLE_PENDING_AUTHORIZED_SIGNATORY_APPROVAL) &&
                hasPermission($permissionFunctionCode.AUTHORIZED_SIGNATORY_APPROVAL)
              )"
          key="checkbox"
          type="selection"
          min-width="40"
        />
        <el-table-column label="Type" width="100px">
          <template v-slot="scope">
            <div>{{ capitalizeFirstLetter(scope.row.invoiceType) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="Status" width="250px">
          <template v-slot="scope">
            <div class="status-tag" :style="getStatusTagStyle(isSellerView ? scope.row.sellerInvoiceStatus : scope.row.buyerInvoiceStatus)">
              {{ isSellerView ? capitalizeFirstLetter(scope.row.sellerInvoiceStatus, '_') : capitalizeFirstLetter(scope.row.buyerInvoiceStatus, '_') }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Order No" prop="orderNo" width="200px"/>
        <el-table-column label="Company" width="150px" show-overflow-tooltip>
          <template v-slot="scope">
            {{ isSellerView ? scope.row.buyerCompanyName : scope.row.sellerCompanyName }}
          </template>
        </el-table-column>
        <el-table-column label="Order Type" width="150px" show-overflow-tooltip>
          <template v-slot="scope">
            <div>{{ capitalizeFirstLetter(scope.row.orderType, '_') }}</div>
          </template>
        </el-table-column>
        <el-table-column label="Vessel/ Terminal" width="150px" show-overflow-tooltip>
          <template v-slot="scope">
            <div>{{ scope.row.orderVesselImo ?  scope.row.orderVesselName : scope.row.orderTerminal}}</div>
          </template>
        </el-table-column>
        <el-table-column label="Item" width="250px" show-overflow-tooltip>
          <template v-slot="scope">
            <div
              v-for="(item, index) in scope.row.invoiceItems"
              :key="index"
            >
              <el-tag
                style="font-size: 11px"
                type="info"
              >
                {{item.description}}
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Quantity" width="100px" >
          <template v-slot="scope">
            <div
              v-for="(item, index) in scope.row.invoiceItems"
              :key="index"
            >
              {{ item.quantity ? `${Number(item.quantity).toFixed(3)} ${item.unit || ''}` : '-'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Supply Date" width="120px">
          <template v-slot="scope">
            <div
              v-for="(item, index) in scope.row.invoiceItems"
              :key="index"
            >
              {{ item.supplyDate ? $moment(item.supplyDate).format('DD-MM-YYYY') : '-'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Invoice Total" width="100px" show-overflow-tooltip>
          <template v-slot="scope">
            <div>{{ `$${numberWithCommas(scope.row.invoiceTotal, 2)}`}}</div>
          </template>
        </el-table-column>
        <el-table-column label="Invoice Due Date" width="120px">
          <template v-slot="scope">
            <div>
              {{ $moment(scope.row.invoiceDueDate).format('DD-MM-YYYY') }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Payment Term" prop="orderPaymentTerm" width="120px">
          <template v-slot="scope">
            <div>{{ capitalizeFirstLetter(scope.row.orderPaymentTerm, '_') }}</div>
          </template>
        </el-table-column>
        <el-table-column label="Order Created By" prop="sellerName" width="150px"/>
        <el-table-column width="50px" fixed="right" v-if="invoiceTableMenuTermList">
          <template v-slot="scope">
            <dropdown-action-menu
              :actionList="invoiceTableMenuTermList"
              :propsData="scope.row"
              @handleDropdownMenuClick="handleInvoiceDropdownMenuClick"
            />
          </template>
        </el-table-column>
      </el-table>
      <div class="div-main-list-page-table-pagination" v-if="!invoiceTableLoading">
        <el-pagination
          background
          @size-change="handleInvoicePageSizeChange"
          @current-change="handleInvoicePageChange"
          :page-size.sync="invoicePagination.pageSize"
          :page-sizes="[5, 10, 20, 50, 100]"
          :current-page="invoicePagination.pageNumber"
          layout="total, sizes, prev, pager, next, jumper"
          :total="invoicePagination.total">
        </el-pagination>
      </div>
    </div>
    <invoice-form
      v-if="view !== INVOICE_VIEW.MAIN_INVOICE"
      :propsView="view"
      :invoiceId="invoiceId"
      :data = this.postedListData
      :orderType = orderType
      :isSellerView='isSellerView'
      @handleCloseInvoiceForm="handleCloseInvoiceForm"
      @handleInvoiceFormEditButtonClick="handleInvoiceFormEditButtonClick"
    />
    <dialog-invoice-send :invoiceId="invoiceSelected ? invoiceSelected.id : ''" :dialogSendInvoice="dialogSendInvoice" @handleSendInvoiceDialogClose="handleSendInvoiceDialogClose"/>
    <dialog-invoice-payment :invoice="selectedInvoiceForPayment || {}" :dialogInvoicePayment="dialogInvoicePayment" :isSellerView="isSellerView" @handleInvoicePaymentDialogClose="dialogInvoicePayment = false"/>
    <file-preview
      @closed="dialogInvoicePayment = true"
      :visible.sync="isPreviewFile"
      :file="previewFileData"
    >
    </file-preview>
     <dialog-blockchain-info @onClose="handleCloseBlockchainInfo" v-if="blockchainInfoVisible" :propsData="blockchainData"/>
   </div>
</template>
<script>
import baseTableMixin from '@/mixins/base-table-mixin'
import DialogInvoicePayment from './components/DialogInvoicePayment'
import DialogInvoiceSend from './components/DialogInvoiceSend'
import InvoiceForm from './components/InvoiceForm'
import { getStatusTagStyle, numberWithCommas, capitalizeFirstLetter } from '@/utils/index.js'
import { INVOICE_TAB, INVOICE_STATUS, INVOICE_VIEW, HEADER_CELL_STYLE, CELL_STYLE } from '@/constants.js'
import { getInvoices, getInvoiceById } from '@/services/modules/invoice.js'
import DropdownActionMenu from '@/components/DropdownActionMenu'
import DialogBlockchainInfo from '@/components/DialogBlockchainInfo'
export default {
  name: 'Invoice',
  mixins: baseTableMixin,
  components: {
    DialogInvoiceSend,
    DialogInvoicePayment,
    InvoiceForm,
    DropdownActionMenu,
    DialogBlockchainInfo
  },
  data () {
    const invoicePagination = {
      pageSize: 50,
      pageNumber: 1,
      total: 0
    }
    return {
      INVOICE_TAB,
      INVOICE_STATUS,
      INVOICE_VIEW,
      HEADER_CELL_STYLE,
      CELL_STYLE,
      isSellerView: true,
      view: INVOICE_VIEW.MAIN_INVOICE,
      viewInvoiceTabActive: null,
      invoiceTableLoading: true,
      invoiceSearch: '',
      invoiceTableData: [],
      invoicePagination,
      invoiceSelected: null,
      dialogSendInvoice: false,
      dialogInvoicePayment: false,
      selectedInvoiceForPayment: null,
      orderType: null,
      invoiceId: null,
      vesselList: [],
      isPreviewFile: false,
      previewFileData: null,
      postedListData: null,
      invoiceTableMenuTermList: [],
      blockchainInfoVisible: false,
      blockchainData: null
    }
  },
  props: {
    data: Object
  },
  created () {
    this.getInitialView()
    this.postedListData = this.data
    if (this.postedListData) {
      this.view = INVOICE_VIEW.CREATE_INVOICE
      this.orderType = this.postedListData.order.orderType
    }
  },
  watch: {
    $route () {
      this.getInitialView()
    }
  },
  methods: {
    getStatusTagStyle,
    numberWithCommas,
    capitalizeFirstLetter,
    getInitialView () {
      this.view = INVOICE_VIEW.MAIN_INVOICE
      this.postedListData = null
      if (this.$router.currentRoute.name === 'MENU_ACCOUNTS_RECEIVABLE_INVOICE') {
        this.isSellerView = true
        if (this.hasPermission(this.$permissionFunctionCode.AUTHORIZED_SIGNATORY_APPROVAL)) {
          this.viewInvoiceTabActive = INVOICE_TAB.RECEIVABLE_PENDING_AUTHORIZED_SIGNATORY_APPROVAL
        } else if (this.hasPermission(this.$permissionFunctionCode.CREDIT_APPROVAL)) {
          this.viewInvoiceTabActive = INVOICE_TAB.RECEIVABLE_PENDING_CREDIT_AUTHORIZATION
        } else {
          this.viewInvoiceTabActive = INVOICE_TAB.RECEIVABLE_AUTHORIZED_SIGNATORY_APPROVED
        }
      } else {
        this.isSellerView = false
        if (this.hasPermission(this.$permissionFunctionCode.AUTHORIZED_SIGNATORY_APPROVAL)) {
          this.viewInvoiceTabActive = INVOICE_TAB.PAYABLE_AUTHORIZED_SIGNATORY_APPROVED
        } else if (this.hasPermission(this.$permissionFunctionCode.TRADER_APPROVAL)) {
          this.viewInvoiceTabActive = INVOICE_TAB.PAYABLE_PENDING_TRADER_APPROVAL
        } else {
          this.viewInvoiceTabActive = INVOICE_TAB.PAYABLE_AUTHORIZED_SIGNATORY_APPROVED
        }
      }
      this.handleGetInvoices(50, 0)
    },
    handleSendInvoiceDialogClose (isSent) {
      this.dialogSendInvoice = false
      if (isSent) {
        this.viewInvoiceTabActive = INVOICE_TAB.RECEIVABLE_SENT
        this.handleGetInvoices(this.invoicePagination.pageSize, 0)
      }
    },
    handleViewIconClick (row) {
      this.postedListData = null
      this.invoiceId = row.id
      this.view = INVOICE_VIEW.VIEW_INVOICE
      this.orderType = row.orderType
    },
    handleInvoiceFormEditButtonClick () {
      this.view = INVOICE_VIEW.EDIT_INVOICE
    },
    handleSendIconClick (row) {
      this.dialogSendInvoice = true
      this.invoiceSelected = row
    },
    handlePaymentReceiptIconClick (row) {
      this.handlePaymentClick(row)
    },
    handlePaymentClick (row) {
      getInvoiceById(row.id).then(res => {
        this.selectedInvoiceForPayment = res.data
        this.dialogInvoicePayment = true
      })
    },
    handleCreateInvoiceSelect (orderType) {
      this.view = INVOICE_VIEW.CREATE_INVOICE
      this.orderType = orderType
    },
    handleInvoicePageChange (pageNumber) {
      this.handleGetInvoices(this.invoicePagination.pageSize, pageNumber - 1)
    },
    handleInvoicePageSizeChange (pageSize) {
      this.handleGetInvoices(pageSize, 0)
    },
    handleInvoiceSelectChange (selection) {
      this.invoiceSelected = selection
    },
    handleGetInvoices (pageSize, pageNumber, isSearch) {
      this.invoiceTableLoading = true
      const invoiceStatus = this.viewInvoiceTabActive.split(/_(.*)/s)[1]
      let invoiceStatusFilter
      if (invoiceStatus === INVOICE_STATUS.REJECTED && this.isSellerView) {
        invoiceStatusFilter = this.isSellerView ? `sellerInvoiceStatus in ('${INVOICE_STATUS.CREDIT_AUTHORIZATION_REJECTED}', '${INVOICE_STATUS.AUTHORIZED_SIGNATORY_REJECTED}')` : `buyerInvoiceStatus:'${INVOICE_STATUS.REJECTED}'`
      } else if (invoiceStatus === INVOICE_STATUS.AUTHORIZED_SIGNATORY_APPROVED) {
        invoiceStatusFilter = this.isSellerView ? `sellerInvoiceStatus in ('${INVOICE_STATUS.CREATED}', '${INVOICE_STATUS.AUTHORIZED_SIGNATORY_APPROVED}')` : `buyerInvoiceStatus:'${INVOICE_STATUS.AUTHORIZED_SIGNATORY_APPROVED}'`
      } else if (invoiceStatus === INVOICE_STATUS.PAID) {
        invoiceStatusFilter = this.isSellerView ? `sellerInvoiceStatus in ('${INVOICE_STATUS.PAID}', '${INVOICE_STATUS.OUTSTANDING}')` : `buyerInvoiceStatus in ('${INVOICE_STATUS.PAID}', '${INVOICE_STATUS.OUTSTANDING}')`
      } else {
        invoiceStatusFilter = this.isSellerView ? `sellerInvoiceStatus:'${invoiceStatus}'` : `buyerInvoiceStatus:'${invoiceStatus}'`
      }
      const query = `${invoiceStatusFilter}${isSearch ? ` and orderNo ~'*${this.invoiceSearch}*'` : ''}&isSellerView=${this.isSellerView}&sort=updatedAt DESC`
      getInvoices(pageSize, pageNumber, query).then(res => {
        if (res?.code === 1000) {
          this.invoiceTableData = res.data.content
          this.invoicePagination.total = res.data.totalElements
          this.invoicePagination.pageNumber = res.data.pageable.pageNumber + 1
          this.invoiceTableLoading = false
          this.invoiceTableMenuTermList = this.getTableMenuList(this.viewInvoiceTabActive)
        }
      })
    },
    handleCloseInvoiceForm (action) {
      if (action !== 'CLOSE') {
        this.getInitialView()
      } else {
        if (action === 'CANCEL') {
          this.viewInvoiceTabActive = INVOICE_TAB.RECEIVABLE_CANCELLED
        } if (action === 'CDNOTE') {
          this.isSellerView ? this.viewInvoiceTabActive = INVOICE_TAB.RECEIVABLE_PAID : this.viewInvoiceTabActive = INVOICE_TAB.PAYABLE_PAID
        } else {
          this.postedListData = null
        }
        this.handleGetInvoices(this.invoicePagination.pageSize, this.invoicePagination.pageNumber - 1)
      }
      this.view = INVOICE_VIEW.MAIN_INVOICE
    },
    authSignatoryTraderApproveReject (isTraderApproval, isApproved) {
      const invoiceIds = this.invoiceSelected.map(d => d.id)
      this.$request.post({
        url: `${isTraderApproval ? this.$apis.traderApproval : this.$apis.authSignatoryApproval}`,
        data: {
          invoiceIds: invoiceIds,
          isApproved: isApproved
        }
      }).then(res => {
        if (res?.code === 1000) {
          if (isApproved) {
            this.$message.success('Invoice has been approved.')
            if (isTraderApproval) {
              this.viewInvoiceTabActive = INVOICE_TAB.PAYABLE_PENDING_AUTHORIZED_SIGNATORY_APPROVAL
            } else {
              this.isSellerView ? this.viewInvoiceTabActive = INVOICE_TAB.RECEIVABLE_AUTHORIZED_SIGNATORY_APPROVED : this.viewInvoiceTabActive = INVOICE_TAB.PAYABLE_AUTHORIZED_SIGNATORY_APPROVED
            }
          } else {
            this.$message.success('Invoice has been rejected.')
            this.viewInvoiceTabActive = INVOICE_TAB.RECEIVABLE_REJECTED
          }
          this.handleGetInvoices(this.invoicePagination.pageSize, 0)
        }
      })
    },
    getTableMenuList (tab) {
      const actionList = [
        { key: 'view', label: 'View', icon: 'el-icon-view' },
        { key: 'receipt', label: 'Receipt', icon: 'el-icon-tickets' },
        { key: 'payment', label: 'Payment', icon: 'el-icon-wallet' },
        { key: 'send', label: 'Send Invoice', icon: 'el-icon-s-promotion' },
        { key: 'viewBlockchainInfo', label: 'Blockchain Info', icon: 'el-icon-warning-outline' }
      ]
      let list
      switch (tab) {
        case INVOICE_TAB.RECEIVABLE_SENT :
        case INVOICE_TAB.RECEIVABLE_PAID :
          list = actionList.filter(item => item.key !== 'payment' && item.key !== 'send')
          break
        case INVOICE_TAB.PAYABLE_AUTHORIZED_SIGNATORY_APPROVED:
        case INVOICE_TAB.PAYABLE_PAID:
          list = actionList.filter(item => item.key !== 'receipt' && item.key !== 'send')
          break
        case INVOICE_TAB.RECEIVABLE_AUTHORIZED_SIGNATORY_APPROVED:
          list = actionList.filter(item => item.key !== 'receipt' && item.key !== 'payment')
          break
        default:
          list = [
            { key: 'view', label: 'View', icon: 'el-icon-view' },
            { key: 'viewBlockchainInfo', label: 'Blockchain Info', icon: 'el-icon-warning-outline' }
          ]
          break
      }
      if (!this.hasPermission(this.$permissionFunctionCode.EDIT)) {
        list = list.filter(item => item.key !== 'receipt' && item.key !== 'payment' && item.key !== 'send')
      }
      return list
    },
    handleCloseBlockchainInfo () {
      this.blockchainInfoVisible = false
    },
    handleInvoiceDropdownMenuClick (action, data) {
      switch (action) {
        case 'send':
          this.handleSendIconClick(data)
          break
        case 'receipt':
        case 'payment':
          this.handlePaymentReceiptIconClick(data)
          break
        case 'view':
          this.handleViewIconClick(data)
          break
        case 'viewBlockchainInfo':
          this.blockchainInfoVisible = true
          this.blockchainData = {
            moduleApi: '/invoices',
            id: data.id
          }
          break
        default:
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
 @import "index";
</style>
